import {useCompany} from "../context/CompanyContext";
import {COMPANIES} from "../constants/companies"; // Import the hook to access company context

// Dropdown component for company selection
const CompanyDropdown = () => {
    const {selectedCompany, setSelectedCompany} = useCompany();

    const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCompany = COMPANIES.find(company => company.id.toString() === event.target.value);

        if (selectedCompany) {
            setSelectedCompany(selectedCompany);
        }
    };

    return (
        <div className="company-dropdown">
            <label htmlFor="company">Select Company ({selectedCompany?.name ?? 'None'}):</label>
            <select id="company" value={selectedCompany.id} onChange={handleCompanyChange}>
                {COMPANIES.map((company) => (
                    <option key={company.id} value={company.id}>
                        {company.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default CompanyDropdown;