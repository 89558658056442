import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import ErrorBoundary from './components/ErrorBoundary'
import "./App.scss";
import ProductTableContainer from "./components/ProductTableContainer";
import Privacy from "./components/Privacy";
import Contact from "./components/Contact";
import RouteNotFound from "./components/RouteNotFound";
import BrandDropdown from "./components/CompanyDropdown";

const router = createBrowserRouter([
  {
    path: "/o/:orderSheetToken",
    element: <ProductTableContainer />,
  },
  {
    path: "/o/privacy",
    element: <Privacy />,
  },
  {
    path: "/o/contact",
    element: <Contact />,

  },
  {
    path: "*",
    element: <RouteNotFound />
  }
]);

const App = () => {
  return (
    <main className="page-container">
      {process.env.REACT_APP_DEV_MODE === 'true' && (<BrandDropdown />)}
      <RouterProvider router={router} />
    </main>
  );
};

export default App;
