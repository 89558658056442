import {SAMPLE_UNIT_PRICE} from "../../constants";
import {CONVERSION_FREE_CURRENCIES} from "../../constants/s3";
import {ColumnDef, ProductForTable} from "../../types/order-sheet";
import ColorColumn from "./Columns/ColorColumn";
import ImageComponent from "./Columns/ImageComponent";
import PriceColumn from "./Columns/PriceColumn";
import {formatDiscount} from "../../utils/formatDiscount";
import React, {useContext} from "react";
import {Company} from "../../types/company";

const getColumns = (currency: string, vatRatePercent: number, baseCurrency: string, showCustomProductCodeColumn: boolean, discount: number | null, company?: Company): ColumnDef[] => {
    const needsCurrencyConversion = !CONVERSION_FREE_CURRENCIES.includes(currency)
    const conversionCurrency = needsCurrencyConversion ? baseCurrency : currency;
    const customerProductCodeColumn: ColumnDef = {
        accessorKey: "customerProductCode",
        sortable: true,
        header: "Your Product Code",
    }
    const discountColumn: ColumnDef | null = discount ? {
        accessorKey: null,
        sortable: false,
        header: "Discount³",
        cell: (key, row, currency) => (
            <span>{formatDiscount(discount)} %</span>
        ),
    } : null
    const usdColumn: ColumnDef = {
        accessorKey: "priceUSD" as keyof ProductForTable,
        sortable: true,
        cell: (key, row, _, exchangeRateFactor) => (
            <PriceColumn
                key={key}
                price={row.price / (exchangeRateFactor || 1)}
                currency="USD"
            />
        ),
        header: "Price (USD)",
    }
    let cols: ColumnDef[] = [
        {
            accessorKey: "category",
            sortable: true,
            selectFilter: true,
            header: "Category",
        },
        {
            accessorKey: "thumbnail",
            cell: (key, row: ProductForTable) => (
                <ImageComponent key={key} thumbnail={row.thumbnail}/>
            ),
            header: "Image",
        },
        {
            accessorKey: "name",
            sortable: true,
            header: "Name",
            cell: (key, row: ProductForTable) => (<>
                {row.name}
                {!!row.specialEdition ? <span className="product-table__badge">{row.specialEdition}</span> : ''}
            </>),
        },
        {
            accessorKey: "brand",
            sortable: true,
            selectFilter: true,
            header: "Brand",
        },
        {
            accessorKey: "color",
            sortable: true,
            selectFilter: true,
            colSpan: 2,
            header: "Variant",
            cell: (key, row: ProductForTable) => (
                row.exactColorName
                    ? <ColorColumn key={key} colorName={row.exactColorName}/>
                    : <ColorColumn key={key} color={row.color} colorName={row.colorName}/>
            ),
        },
        {
            accessorKey: "variantProductId",
            sortable: true,
            header: showCustomProductCodeColumn ? "Our Product Code" : "Product Code",
        },
        ...showCustomProductCodeColumn ? [customerProductCodeColumn] : [],
        {
            accessorKey: "ean",
            header: "EAN/Barcode",
        },
        {
            accessorKey: "msrp",
            sortable: true,
            header:
                vatRatePercent && Number(vatRatePercent) > 0
                    ? "MSRP incl. VAT"
                    : "MSRP",
            cell: (key, row, currency) => (
                <PriceColumn key={key} price={row.msrp} currency={currency}/>
            ),
        },
        {
            accessorKey: "price",
            sortable: true,
            header: "Price¹",
            cell: (key, row, currency) => (
                <PriceColumn key={key} price={row.price} currency={currency}/>
            ),
        },
        ...needsCurrencyConversion ? [usdColumn] : [],
        {
            accessorKey: "moq",
            sortable: true,
            header: "Min. Box QTY",
        },
        {
            accessorKey: "orderQty",
            sortable: true,
            header: "Order Box QTY",
        },
        {
            accessorKey: "sampleQty",
            sortable: true,
            header: "Sample QTY²",
            subHeader: `(Token Fee ${SAMPLE_UNIT_PRICE} ${conversionCurrency})`,
            title: `Each sample is charged with a token fee of ${SAMPLE_UNIT_PRICE} ${conversionCurrency}. \n We will refund this fee on your next order.`,
        },
        {
            accessorKey: "testerQty",
            sortable: true,
            subHeader: `(Free of charge)`,
            header: "POS Tester QTY",
        },
        {
            sortable: true,
            accessorKey: "calculatedQty",
            header: "Product QTY",
        },
        ...discountColumn !== null ? [discountColumn] : [],
        {
            accessorKey: "calculatedPriceWithPotentialDiscountApplied",
            sortable: true,
            cell: (key, row, currency) => (
                <PriceColumn key={key} price={row.calculatedPriceWithPotentialDiscountApplied} currency={currency}/>
            ),
            header: "Product Amount",
        },
    ];

    if(company?.id === 'fnf') {
        const indexOfBoxQty: number = cols.findIndex(col => col.accessorKey === 'msrp');
        const manufactererCol: ColumnDef = {
            accessorKey: "funfactorySku",
            sortable: true,
            header: "Funfactory SKU",
        };

        cols.splice(indexOfBoxQty, 0, manufactererCol);

        cols = cols.filter(el => !['sampleQty', 'testerQty'].includes(el.accessorKey ?? ''))
    }

    return cols;
};

export default getColumns;
