import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {Company} from "../types/company";
import {COMPANIES, getCompanyByHostname} from "../constants/companies";

// Define the shape of the context
interface CompanyContextType {
    selectedCompany: Company;
    setSelectedCompany: (company: Company) => void;
}

// Create the context with an initial value of null
const CompanyContext = createContext<CompanyContextType | undefined>(undefined);

// Custom hook to use the CompanyContext
export const useCompany = () => {
    const context = useContext(CompanyContext);
    if (!context) {
        throw new Error("useCompany must be used within a CompanyProvider");
    }
    return context;
};

// Context provider component
export const CompanyProvider = ({ children }: { children: ReactNode }) => {
    const initialCompany = getCompanyByHostname(window.location.hostname ?? '') ?? COMPANIES[0];

    const [selectedCompany, setSelectedCompany] = useState<Company>(initialCompany);

    useEffect(() => {
        document.documentElement.dataset.company = selectedCompany.id;
    }, [selectedCompany]);

    return (
        <CompanyContext.Provider value={{ selectedCompany, setSelectedCompany }}>
            {children}
        </CompanyContext.Provider>
    );
};