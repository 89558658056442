/* eslint-disable react/style-prop-object */
import React, {useContext} from "react";
import {useCompany} from "../context/CompanyContext";

const Header = ({ leftContent }: { leftContent?: React.ReactNode }) => {
    const {selectedCompany} = useCompany();

    return (
    <div className="headline">
      <div className="headline__text">{leftContent}</div>

      <div className="headline__logo-wrap">
        <img src={selectedCompany.logoSrc} alt={selectedCompany.name + ' Logo'} />
      </div>
    </div>
  );
};

export default Header;
